<template>
    
    <div class="text-center mt-5 " v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" style="width: 30px; height: 30px;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>
    
  

</template>
<script>
    
import axios from 'axios'
import moment from 'moment'
import { ref, onMounted } from 'vue'


export default {
    props:['id'],
    setup(props){
        
        const encuesta = ref({});
        const urlbase = axios.defaults.baseURL;
        const creacion = ref('');
        const actualizacion = ref('');
        
        const preguntas = ref({});
        const cargando = ref(true);

        const idencuesta = props.id;

            const informacionEncuesta = () => {
                
                const url = "api/infoencuesta/"+props.id 
                axios.get(url)
                .then(response => {

                        encuesta.value = response.data
                        creacion.value = moment(response.data.created_at).format('MMM D [de] YYYY, h:mm:ss a');
                        actualizacion.value = moment(response.data.updated_at).format('MMM D [de] YYYY, h:mm:ss a');

                         cargando.value = false;


                }).catch( error => {
                        console.error(error);
                })
            }

            // const preguntasEncuesta = () =>{

            //     const url = "api/preguntasxencuesta/"+props.id 
            //     axios.get(url,{ headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            //     .then(response => {

            //         preguntas.value = response.data

            //     }).catch( error => {
            //             console.error(error);
            //     })
            // }
            
            
            onMounted(() => {
                
                informacionEncuesta();
                // preguntasEncuesta();
                                    
                })


        // Return
        return { 
                encuesta, 
                creacion, 
                actualizacion, 
                urlbase,
                preguntas,
                cargando,
                idencuesta
            }

    }

}
</script>

